export const useImageUrl = (content) => {
  if (!content) {
    return
  }
  const config = useRuntimeConfig().public

  const imageUrl = computed (() => {
    return config.assetsUrl + content.url
  })
  const largeUrl = computed (() => {
    return config.assetsUrl + content.formats?.large?.url
  })
  const mediumUrl = computed (() => {
    return config.assetsUrl + content.formats?.medium?.url
  })
  const smallUrl = computed (() => {
    return config.assetsUrl + content.formats?.small?.url
  })
  
  return { imageUrl, largeUrl, mediumUrl, smallUrl }
}