<script setup>
const props = defineProps({
  content: {
    type: Object
  },
  classList: {
    type: String
  },
  alt: {
    type: String,
    default: ''
  }
})
const { imageUrl, largeUrl, mediumUrl, smallUrl } = useImageUrl(props.content)

</script>

<template>
  <picture>
    <source v-if="content.formats.large"
      :srcset="largeUrl"
      media="(min-width: 1000px)"
      :height="content.formats.large.height"
      :width="content.formats.large.width"
    >
    <source v-if="content.formats.medium"
      :srcset="mediumUrl"
      media="(min-width: 500px)"
      :height="content.formats.medium.height"
      :width="content.formats.medium.width"
    >
    <img :src="content.formats.small ? smallUrl : imageUrl"
      :height="content.formats.small ? props.content.formats.small.height : content.height"
      :width="content.formats.small ? content.formats.small.width : content.width"
      :alt="alt" :class="classList"
    />
  </picture>
</template>